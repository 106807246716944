code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1,
h2 {
	color: var(--red);
	line-height: 1.1;
}

h1 {
	font-size: 2rem;
}

@media (min-width: 1000px) {
	h1 {
		font-size: 4rem;
	}
}

h2 {
	font-size: 2rem;
	margin-top: 0;
}
