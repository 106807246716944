#curve {
	position: absolute;
	bottom: 32%;
	width: 100%;
}

#curve path {
	fill: #fff;
}

.active {
	background: linear-gradient(
		90deg,
		rgba(203, 0, 55, 0.8),
		rgba(255, 0, 0, 0.8)
	);
	color: white !important;
}

button,
input[type="submit"],
input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	transition: 0.3s ease;
	@media (hover: hover) {
		&:hover {
			cursor: pointer;
			
		}
	}
}

.player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-10px);
	}
}

@media (min-width: 1600px) {
	.bigCard {
		transform: scale(1) !important;
	}
}

.before {
	transform: rotateY(20deg) scale(0.7);
}

.after {
	transform: rotateY(-20deg) scale(0.7);
}

.fade {
	animation: fade-in-keyframes 0.5s;
}
@keyframes fade-in-keyframes {
	0% {
		transform: rotateY(-360deg);
		opacity: 1;
	}
	45% {
		transform: rotateY(-180deg);
		opacity: 0;
	}
	65% {
		transform: rotateY(-180deg);
		opacity: 0;
	}
	100% {
		transform: rotateY(0deg);
		opacity: 1;
	}
}

.wrapper_inner {
	max-width: 1560px;
	margin: 2rem auto;
	text-align: center;
}

.navImgLink {
	padding: 0 !important;
}

.logo_powerSpeed {
	margin: 0 auto;
}

iframe#\36 28516638 {
	height: 220px;
	max-width: 80vw;
	aspect-ratio: 16 / 9;
}

#app {
	padding: 3rem 2rem;
	box-sizing: border-box;
	max-width: 1600px;
	margin: 0 auto;
}

@media (max-width: 600px) {
	#app {
		padding-top: 0;
	}
}

.slick-prev,
.slick-next {
	z-index: 20;
	width: 50%;
	height: 100%;
}

.slick-prev {
	left: 0;
}

.slick-next {
	right: 0;
}

@media (max-width: 600px) {
	.slick-prev:before {
		left: 10%;
	}

	.slick-next:before {
		right: 10%;
	}
}

@media (min-width: 1000px) {
	.slick-prev:before {
		right: 18%;
	}

	.slick-next:before {
		left: 19%;
	}
}

.slick-prev:before,
.slick-next:before {
	color: #fff;
	font-size: 24px;
	position: absolute;
	border: 2px solid var(--red);
	border-radius: 100%;
	background-color: var(--red);
	padding: 0.155em 0.05em 0;
}

.slick-prev:hover,
.slick-next:hover {
	box-shadow: unset;
}

.slick-next:focus::before, .slick-next:hover::before, .slick-prev:focus::before, .slick-prev:hover::before {
    opacity: 1;
    background: #FFF;
    color: var(--red);
}

.slick-slide {
	transition: all 0.5s ease-in;
	text-align: center;
}

.slick-slide:not(.slick-current) {
	opacity: 0.5;
	transform: scale(0.8) !important;
}

.slick-slide:not(.slick-current) p,
.slick-slide:not(.slick-current) h3 {
	display: none;
}

.slick-current .slickContent {
	transition: unset;
	text-align: center;
	margin: 0 auto;
	margin-top: 2rem;
	max-width: 80%;
}

.slick-current .slickContent h3 {
	display: unset;
	color: var(--red);
	font-size: 2rem;
}